import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Subscribe from "../components/subscribe"

class Index extends React.Component {
  render() {
    const { data } = this.props
    const social = data.site.siteMetadata.social
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle} social={social}>
        <SEO
          title="Hi I'm Nishith 👋"
          keywords={[`blog`, `gatsby`, `javascript`, `react`, `newsletter`, `react native`,
            `nishith`, `nishith studio`, `reactjs`, `npatel`, `pnishith`, `tasteoftechzone`,
            `writer`, `coder`, `learn to code`, `nishith patel`, `nishithpatel`, `nishithpatel react`]}
        />
        <Bio />
        <Subscribe />
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          twitter
          facebook
          github
          instagram
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
