import React from "react"
import { Link } from "gatsby"

class Subscribe extends React.Component {
    render() {
        const { titleText } = this.props;
        return (
            <form action="https://buttondown.email/api/emails/embed-subscribe/pnishith"
                method="post" target="popupwindow"
                className="embeddable-buttondown-form"
            >
                <h4>{titleText ? titleText : 'I keep up updating newsletter week by week to share what I learn.'}</h4>
                <h6>React and Personal growth. No spam! I promise.</h6>
                <div className='input-wrapper'>
                    <input
                        className="drop-input"
                        placeholder='Drop your email here'
                        spellCheck={false}
                        type="email" name="email" id="bd-email"
                    />
                    <input type="hidden" defaultValue={1} name="embed" />
                    <input type="submit" value="Subscribe me!" className="button" />
                </div>

                <p className="read-learning">
                    <Link to='/newsletter'>Read my learnings</Link>
                </p>
            </form>

        )
    }
}

export default Subscribe